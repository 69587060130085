import React from 'react';
import logo from './img/nissi-logo.png';
import mainImage from './img/main-image.png';
import mainImageMobile from './img/main-image-mobile.png';
import joyce from './img/joyceadedugbe.png';
import serviceImg1 from './img/services-img1.png';
import serviceImg2 from './img/services-img2.png';
import serviceImg3 from './img/services-img3.png';
import serviceImg4 from './img/services-img4.png';
import './App.css';
import 'popper.js';
import 'jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './style.css';
import './responsive.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './scripts.js';



class App extends React.Component {
constructor(props, context) {
    super(props, context);
    AOS.init();
  }

  componentWillReceiveProps() {
    AOS.refresh();
  }

render() {
    return (
      <div className="App">

      <header>
        <div className="desktop-outer w-100 h-100 bg-white">
          <nav className="container d-flex flex-row align-items-center justify-content-between h-100">

              <a href="/" className="logo-link-light"><img src={logo} alt="" className="logo logo-link-light my-3" /></a>

              <button className="hamburger hamburger--spin" type="button">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>

              <div className="desktop-nav menu-link-container">
                <div className="menu-link pl-lg-5 cl-effect-4 pink">
                  <a href="#about">About</a>
                </div>
                <div className="menu-link pl-lg-5 cl-effect-4 lightblue">
                  <a href="#services">Services</a>
                </div>

                <div className="menu-link pl-lg-5 cl-effect-4 nude">
                  <a href="https://15174.portal.athenahealth.com/" target="_blank" rel="noopener noreferrer">Patient Portal</a>
                </div>
                <div className="menu-link pl-lg-5 cl-effect-4 blue">
                  <a href="#contact">Contact</a>
                </div>
              </div>
          </nav>
        </div>


        <div className="mobile-nav menu-link-container">
          <div className="mobile-nav-inner">
            <div className="menu-link my-5 py-3">
              <a href="#about" className="mobile-link">About</a>
            </div>
            <div className="menu-link my-5 py-3">
              <a href="#services" className="mobile-link">Services</a>
            </div>
            <div className="menu-link my-5 py-3">
              <a href="https://15174.portal.athenahealth.com/" target="_blank" className="mobile-link" rel="noopener noreferrer">Patient Portal</a>
            </div>
            <div className="menu-link my-5 py-3">
              <a href="#contact" className="mobile-link">Contact</a>
            </div>
          </div>
        </div>
      </header>





      <section className="nissi-home">
        <div className="container h-100 d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <div className="home-text pr-0 pr-lg-5 d-flex flex-column justify-content-center">
            <h1 className="text-center text-lg-left headline-blue" data-aos="fade-up" data-aos-duration="500">Treat & manage your diabetes</h1>
            <span className="tagline text-center text-lg-left px-5 px-lg-0 mb-2" data-aos="fade-up" data-aos-duration="1000">Providing diabetes care & management in LindenHurst,&nbsp;NY</span>
            <a href="tel:631-608-2878" className="btn tel d-flex justify-content-center align-items-center mx-auto mx-lg-0" data-aos="fade-up" data-aos-duration="1500">(631) 608-2878</a>
          </div>
          <div className="home-image">
            <img src={mainImage} alt="" className="main-image d-none d-lg-block" />
            <img src={mainImageMobile} alt="" className="mobile-main-image w-100 d-block d-lg-none" />
          </div>
        </div>
      </section>


      <section className="nissi-about">
        <a className="anchor" id="about"></a>
        <div className="about-outer w-100">
          <div className="about container text-center py-4 py-lg-5" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="250">
            <h2 className="headline-pink pt-5 mt-5 mt-lg-0">About</h2>
              <div className="divider divider-lightblue"></div>
            <p className="about-text pb-5 px-3">
              Nissi Diabetes Care is a Nurse Practitioner-owned Private Medical Practice dedicated to treatment and management of all types of diabetes including those using Insulin Pumps, Insulin Sensors, and the latest available technology to provide optimal treatment and care.
            </p>
          </div>
        </div>

        <div className="mv-outer w-100 py-5">
          <div className="mv container d-flex flex-column flex-lg-row py-0 py-lg-3">
            <div className="vision pl-0 pl-lg-4 mt-5 mt-lg-0" data-aos="fade-up" data-aos-duration="500" data-aos-offset="250">
              <h4 className="headline-blue text-center text-lg-left">Vision</h4>
                <div className="divider divider-lightblue d-block d-lg-none my-4"></div>
              <p className="mb-lg-0 pr-lg-4 text-center text-lg-left px-3 px-lg-0">To help people with diabetes achieve optimal control of the disease, minimize complications, and extend&nbsp;life.</p>
            </div>
            <div className="mission pl-0 pl-lg-4 mt-5 mt-lg-0" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="250">
              <h4 className="headline-blue text-center text-lg-left">Mission</h4>
                <div className="divider divider-pink d-block d-lg-none my-4"></div>
              <p className="mb-lg-0 text-center text-lg-left px-3 px-lg-0">Transform diabetes care for better health and prevent spreading of the disease by creating awareness through&nbsp;education.</p>
            </div>
          </div>
        </div>

        <div className="np-outer w-100 py-5">
          <div className="np container text-center py-4">
            <h3 className="headline-blue" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="250">Meet Your NP!</h3>
              <div className="divider divider-pink"></div>
          </div>
          <div className="np-info-outer container pb-4">
            <div className="np-info-inner d-flex flex-column-reverse flex-lg-row mx-auto">
              <div className="np-info d-flex flex-column text-center text-lg-right px-3 pr-lg-5" data-aos="fade-up" data-aos-duration="500" data-aos-offset="300">
                <p className="np-name pb-1 mb-3 mb-lg-0">Joyce Adedugbe</p>
                <p className="np-bio mb-5">Joyce is an Adult Nurse Practitioner with a passion for caring for people with diabetes. She is Board Certified in Advance Diabetes Management and is a Certified Diabetes Educator and Insulin Pump Trainer with extensive experience in diabetes care.</p>
                <p className="np-membership-title pb-1 mb-3 mb-lg-0">Professional Membership</p>
                <ul className="np-membership text-center text-lg-right">
                  <li>American Association of Nurse&nbsp;Practitioners</li>
                  <li>New York State Nurse Practitioner&nbsp;Association</li>
                  <li>American Association of Diabetes&nbsp;Educators</li>
                </ul>
              </div>
              <div className="np-photo h-100 pl-0 pl-lg-5" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="300">
                <img src={joyce} alt="" className="mx-auto mb-5 m-lg-0 d-block" />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="nissi-services position-relative">
        <a className="anchor" id="services"></a>
        <div className="services-outer w-100"></div>
        <div className="services-inner w-100 position-relative">
          <div className="container text-center pt-4 pt-lg-5">
            <h2 className="headline-blue pt-5 mt-2 mt-lg-0" data-aos="fade-up">Services</h2>
              <div className="divider divider-lightblue"></div>
          </div>
          <div className="services container pb-4 pb-lg-5">
            <div className="service-frame mb-5" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="250">
              <div className="service-frame-inner w-100 d-flex flex-column flex-lg-row">
                <div className="service-img divider-blue d-flex flex-row justify-content-center align-items-center">
                  <img src={serviceImg1} alt="" className="" width="90" />
                </div>
                <div className="service-title p-4 py-lg-4 px-lg-5 d-flex flex-row align-items-center">
                  <h4 className="headline-blue text-center text-lg-left mx-auto mx-0 mb-0">Diabetes Consultation & Treatment Management</h4>
                </div>
              </div>
            </div>

            <div className="service-frame mb-5" data-aos="fade-up" data-aos-duration="1200" data-aos-offset="250">
              <div className="service-frame-inner w-100 d-flex flex-column flex-lg-row">
                <div className="service-img divider-lightblue d-flex flex-row justify-content-center align-items-center">
                  <img src={serviceImg2} alt="" className="" width="92" />
                </div>
                <div className="service-title p-4 py-lg-4 px-lg-5 d-flex flex-row align-items-center">
                  <h4 className="headline-blue text-center text-lg-left mx-auto mx-0 mb-0">Obesity Management</h4>
                </div>
              </div>
            </div>

            <div className="service-frame mb-5" data-aos="fade-up" data-aos-duration="1300" data-aos-offset="250">
              <div className="service-frame-inner w-100 d-flex flex-column flex-lg-row">
                <div className="service-img divider-pink d-flex flex-row justify-content-center align-items-center">
                  <img src={serviceImg3} alt="" className="" width="90" />
                </div>
                <div className="service-title p-4 py-lg-4 px-lg-5 d-flex flex-row align-items-center">
                  <h4 className="headline-blue text-center text-lg-left mx-auto mx-0 mb-0">Medical Nutrition Therapy</h4>
                </div>
              </div>
            </div>

            <div className="service-frame" data-aos="fade-up" data-aos-duration="1400" data-aos-offset="250">
              <div className="service-frame-inner w-100 d-flex flex-column flex-lg-row">
                <div className="service-img divider-blue d-flex flex-row justify-content-center align-items-center">
                  <img src={serviceImg4} alt="" className="" width="99" />
                </div>
                <div className="service-title p-4 py-lg-4 px-lg-5 d-flex flex-row align-items-center">
                  <h4 className="headline-blue text-center text-lg-left mx-auto mx-0 mb-0">In-Office Visit & Telemedicine</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="nissi-insurance pb-4">
        <div className="insurance-outer container py-4 py-lg-5" data-aos="fade-up" data-aos-duration="1400" data-aos-offset="250">
          <h4 className="headline-blue text-center mt-4 mt-lg-0">Insurances We Accept</h4>
            <div className="divider divider-pink"></div>
          <ul className="insurance-list text-center">
            <li>Medicare</li>
            <li>Medicaid</li>
            <li>Empire Blue Cross / Blue Shield</li>
            <li>AgeWell</li>
            <li>Fidelis</li>
            <li>Oscar</li>
            <li className="pb-0">United Health Care</li>
          </ul>

        </div>
      </section>



      <footer className="pt-5 pb-2">
        <a className="anchor" id="contact"></a>
        <div className="container pt-4">
          <div className="row">
            <div className="col-12 col-lg-4 text-center pb-4 pb-lg-0">
              <span className="footer-title">Email</span>
              <div className="divider divider-lightblue"></div>
              <p><a href="mailto:jadedugbe@nissidiabetes.com">jadedugbe@nissidiabetes.com</a></p>
            </div>
            <div className="col-12 col-lg-4 text-center pb-4 pb-lg-0">
              <span className="footer-title">LindenHurst Location</span>
              <div className="divider divider-pink"></div>
              <p>150 E Sunrise Hwy Suite L-20<br/>Lindenhurst NY, 11757<br/>(631) 608-2878 (T)<br/>(631) 608-2879 (F)<br/><br/>Tuesday : 9am - 5pm
<br/>Saturday: 9am - 3pm</p>
            </div>
            <div className="col-12 col-lg-4 text-center">
              <span className="footer-title">Riverhead Location</span>
              <div className="divider divider-lightblue"></div>
              <p>448 Griffing Ave<br/>Riverhead NY, 11901<br/>(631) 909-8030 (T)<br/><br/>Monday : 9am - 5pm<br/>
Thursday: 9am - 5pm</p>
            </div>
          </div>
          <div className="row">
            <p className="copyright text-center mx-auto pt-4">
              {(new Date().getFullYear())} &copy; Nissi Diabetes Care, Inc.
            </p>
          </div>
        </div>
      </footer>



      </div>
   );
}
}




export default App;
